<template>
  <div id="video">
    <div class="video-content">
      <div
        class="video-item"
        v-for="(item, index) in videoList"
        :key="index"
        @click="goDetail(item)"
      >
        <van-image
          width="100%"
          height="110px"
          :src="item.coverUrl"
          fit="contain"
        />

        <div class="info">
          <div class="info-title">{{ item.videoName }}</div>
          <div><van-icon name="play-circle-o" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../common/js/api';
export default {
  props: {},
  data() {
    return {
      videoList: [],
    };
  },
  computed: {},
  created() {
    this.renderData();
  },
  mounted() {},
  watch: {},
  methods: {
    goDetail(item) {
      window.sessionStorage.setItem('videoInfo', JSON.stringify(item));
      this.$router.push('/videoDetail');
    },
    renderData() {
      let params = {
        name: this.$route.query.id,
      };
      this.$post(api.videoService, params).then((res) => {
        let data = res.data;
        console.log(data);
        this.videoList = data;
      });
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
#video {
  padding: 10px 20px;
  background: #fff;
  .video-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: auto;
    .video-item {
      width: 48%;
      height: 162px;
      margin-bottom: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 5px;
      position: relative;
      .time {
        position: absolute;
        left: 12px;
        top: 90px;
        font-size: 10px;
        color: #ffffff;
      }
      .info {
        padding: 5px 12px;
        .info-title {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }
      }
    }
  }
}
</style>
